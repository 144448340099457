import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import media from "../../shared/mediaQuery"
import poqqyLogoSrc from "../../images/poqqy-logo-white.svg"
import Button from "../../shared/components/buttons/button"
import ReactGA from "react-ga"
import CurrentPriceCard from "./currentPriceCard"
import Header from "../../shared/components/header"

const HeaderContainer = styled.header`
  height: 70vh;
  background-size: cover;
  background-image: url(/images/emission-header.svg);
  
  ${media.phone`
    height: 50vh;
  `}
`

const LinksContainer = styled.div`
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: ${props => props.theme.margins.size05} ${props => props.theme.margins.size06};
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.margins.size10} ${props => props.theme.margins.size05} 0 ${props => props.theme.margins.size05};
  
  ${media.phone`
    justify-content: center;
    padding: 0;
  `}
`

const SloganAndDescriptionContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.size07};
  
  ${media.phone`
    margin-bottom: 0;
  `};
`

const Slogan = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size06};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: ${props => props.theme.margins.size03};
  
  ${media.phone`
    font-size: ${props => props.theme.fontSizes.size04};
  `};
`

const Description = styled.span`
  text-align: center;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size04};
  width: 100%;
  margin-bottom: ${props => props.theme.margins.size04};
  
  ${media.phone`
    width: 100%;
  `}
`

const PriceContainer = styled.div`
  position: absolute;
  bottom: -40px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const EmissionHeader = (props) => {
    const openEmailModal = () => {
        ReactGA.event({
            category: "CO2",
            action: "User clicked open wallet",
        })

        props.openCaptureModal();
    }

    return (
        <HeaderContainer>
            <div
                style={{
                    position: "relative",
                    height: "100%",
                    margin: `0 auto`,
                }}>
                <Header/>

                <Container>
                    <SloganAndDescriptionContainer>
                        <Slogan>CO2 certificaten</Slogan>
                        <Description>
                            poqqy biedt de meest eenvoudige manier om <br/>
                            certificaten te kopen en verkopen
                        </Description>
                        <Button onClick={openEmailModal}>Open wallet!</Button>
                    </SloganAndDescriptionContainer>
                </Container>

                <PriceContainer>
                    <CurrentPriceCard/>
                </PriceContainer>
            </div>
        </HeaderContainer>)
}

EmissionHeader.propTypes = {
    siteTitle: PropTypes.string,
}

EmissionHeader.defaultProps = {
    siteTitle: ``,
}

export default EmissionHeader
