import React, { Component } from "react"
import styled from "styled-components"
import {
    ActionFooter,
    CloseIcon,
    Content,
    DescriptionInputGroup,
    ModalContentContainer,
    SmallDivider,
} from "./captureEmailModalStyles"
import PropTypes from "prop-types"
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GradientButton from "../../../shared/components/buttons/gradientButton"

const SuccessIcon = styled(props => <FontAwesomeIcon {...props} />)`
    font-size: ${props => props.theme.fontSizes.size09};
    color: ${props => props.theme.colors.sulu};
`

const Description = styled.span`
    text-align: center;
    line-height: ${props => props.theme.fontSizes.size05};
    font-size: ${props => props.theme.fontSizes.size02};
    color: ${props => props.theme.colors.grey40};
    width: 80%;
`

class CaptureEmailModalSuccess extends Component {
    render() {
        return (
            <ModalContentContainer>
                <CloseIcon onClick={() => this.props.closeEmailCaptureModal()}
                           size={"lg"}
                           icon={faTimes}/>

                <Content>
                    <SuccessIcon size={"2x"} icon={faCheckCircle}/>
                    <SmallDivider/>

                    <DescriptionInputGroup>
                        <Description>
                            Top! Je emailadres is succesvol geregistreerd.
                            <br/>
                            We houden je op de hoogte van verdere ontwikkelingen op ons platform.
                        </Description>
                    </DescriptionInputGroup>
                </Content>

                <ActionFooter>
                    <GradientButton onClick={() => this.props.closeEmailCaptureModal()}>
                        Ga terug
                    </GradientButton>
                </ActionFooter>
            </ModalContentContainer>
        )
    }
}

CaptureEmailModalSuccess.propTypes = {
    closeEmailCaptureModal: PropTypes.func,
}

export default CaptureEmailModalSuccess
