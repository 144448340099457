import React, {useEffect, useState} from "react"
import styled from "styled-components"
import Card from "../../shared/components/card"
import {optimizerApi} from "../../shared/api";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${props => props.theme.margins.size07};
`

const Label = styled.div`
    font-size: ${props => props.theme.fontSizes.size02};
    color: ${props => props.theme.colors.grey65};
`

const Price = styled.div`
    font-size: ${props => props.theme.fontSizes.size05};
    font-weight: bold;
    color: ${props => props.theme.colors.grey50};
`

const IncreaseContainer = styled.div`
    display: flex;
    align-items: center;
`

const Increase = styled.div`
    background-color: ${props => props.isPositive ? props.theme.colors.sulu95 : props.theme.colors.wildWaterLemon98};
    padding: ${props => props.theme.margins.size01} ${props => props.theme.margins.size03};
    border-radius: 12px;
`

const IncreaseText = styled.div`
    color: ${props => props.isPositive ? props.theme.colors.sulu45 : props.theme.colors.wildWaterLemon};
    font-size: ${props => props.theme.fontSizes.size02};
    font-weight: 500;
`

const CurrentPriceCard = () => {
    const [price, setPrice] = useState(null);
    const [percentage, setPercentage] = useState(null);

    useEffect(() => {
        optimizerApi.get('/emission-allowances/day')
            .then(({data}) => {
                setPrice(data.lastTradePrice);
                setPercentage(data.percentualChange);
            });
    });

    const isPositivePercentage = percentage > 0;

    return (
        <Card>
            <Container>
                <PriceContainer>
                    <Label>Laatste prijs</Label>
                    <Price >€ {price}</Price>
                </PriceContainer>

                <IncreaseContainer>
                    <Increase isPositive={isPositivePercentage}>
                        <IncreaseText isPositive={isPositivePercentage}>
                            {isPositivePercentage ? '+' : ''} {percentage && percentage.toFixed(2)}%
                        </IncreaseText>
                    </Increase>
                </IncreaseContainer>
            </Container>
        </Card>
    )
}

export default CurrentPriceCard
