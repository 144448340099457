import React from "react"
import PropTypes from 'prop-types';
import styled from "styled-components"
import Button from "../../shared/components/buttons/button"
import media from "../../shared/mediaQuery"
import ReactGA from "react-ga"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #99e37b, ${props => props.theme.colors.emerald});
    padding: ${props => props.theme.margins.size09} 0;
`

const BannerTitle = styled.div`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.size06};
    font-weight: 500;
    margin-bottom: ${props => props.theme.margins.size04};
    
    ${media.phone`
        font-size: ${props => props.theme.fontSizes.size05};
    `};
`

const BannerDescription = styled.div`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: 500;
    margin-bottom: ${props => props.theme.margins.size05};
`

const Banner = (props) => {
    const openWallet = () => {
        ReactGA.event({
            category: "CO2",
            action: "User clicked open wallet",
        })

        props.openEmailCaptureModal();
    }

    return (
        <Container>
            <BannerTitle>
                Werk mee aan een duurzame wereld
            </BannerTitle>
            <BannerDescription>
                Wacht niet langer, open nu je eerste CO2 wallet
            </BannerDescription>
            <Button onClick={openWallet}>Open wallet!</Button>
        </Container>
    )
}

Banner.propTypes = {
    openEmailCaptureModal: PropTypes.func.isRequired
}

export default Banner
