import React, { Component } from "react"
import CaptureEmailModalContent from "./captureEmailModalContent"
import CaptureEmailModalSuccess from "./captureEmailModalSuccess"
import CaptureEmailModalError from "./captureEmailModalError"
import Modal from "../../../shared/components/modal"
import { poqqyApiUrl, sendPost } from "../../../shared/api"

class CaptureEmailModal extends Component {
    state = {
        emailSubscriptionSuccess: false,
        emailSubscriptionError: false,
        emailSubscriptionLoading: false,
        emailPermission: false,
        email: "",
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
            this.setState({
                emailSubscriptionSuccess: false,
                emailSubscriptionError: false,
                emailSubscriptionLoading: false,
                emailPermission: false,
                email: "",
            })
        }
    }

    changeEmailPermission = () => this.setState({ emailPermission: !this.state.emailPermission })
    changeEmail = (value) => this.setState({ email: value })
    sendEmailSubscription = () => {
        this.setState({emailSubscriptionLoading: true})
        sendPost(`${poqqyApiUrl}/onboarding`, { "email": this.state.email })
            .then(() => this.setState({ emailSubscriptionSuccess: true, emailSubscriptionLoading: false }))
            .catch(() => this.setState({ emailSubscriptionError: true, emailSubscriptionLoading: false }))
    }

    render() {
        return (
            <Modal width='450px' isOpen={this.props.isOpen}>
                {this.state.emailSubscriptionSuccess &&
                <CaptureEmailModalSuccess closeEmailCaptureModal={this.props.closeEmailCaptureModal}/>}
                {this.state.emailSubscriptionError && !this.state.emailSubscriptionSuccess &&
                <CaptureEmailModalError
                    emailSubscriptionLoading={this.state.emailSubscriptionLoading}
                    closeEmailCaptureModal={this.props.closeEmailCaptureModal}
                    sendEmailSubscription={this.sendEmailSubscription}/>
                }
                {!this.state.emailSubscriptionSuccess && !this.state.emailSubscriptionError &&
                <CaptureEmailModalContent
                    email={this.state.email}
                    emailPermission={this.state.emailPermission}
                    emailSubscriptionLoading={this.state.emailSubscriptionLoading}
                    sendEmailSubscription={this.sendEmailSubscription}
                    changeEmailPermission={this.changeEmailPermission}
                    closeEmailCaptureModal={this.props.closeEmailCaptureModal}
                    changeEmail={this.changeEmail}/>
                }
            </Modal>
        )
    }
}

export default CaptureEmailModal
