import React, {PureComponent} from "react"
import Layout from "../components/shared/layout"
import SEO from "../components/shared/seo"
import { theme } from "../shared/theme"
import styled, { ThemeProvider } from "styled-components"
import EmissionHeader from "../components/certificates/emissionHeader"
import KeyPoints from "../components/certificates/keyPoints"
import Overview from "../components/certificates/overview"
import Banner from "../components/certificates/banner"
import Footer from "../components/shared/footer"
import media from "../shared/mediaQuery"
import CaptureEmailModal from "../components/certificates/emailModal/captureEmailModal"

const Divider = styled.div`
    margin-bottom: ${props => props.theme.margins.size11};
    
    ${media.phone`
        margin-bottom: ${props => props.theme.margins.size08};
    `}
`

const ContentContainer = styled.div`
  box-sizing: border-box;
  max-width: 960px;
  margin: 0 auto;
  padding: ${props => props.theme.margins.size10} ${props => props.theme.margins.size08};
  
  ${media.phone`
    padding: ${props => props.theme.margins.size09} ${props => props.theme.margins.size07};
    margin: 0;
  `}
`

class EmissionCertificatePage extends PureComponent {
    state = {
        isCaptureModalOpen: false
    }

    openCaptureModal = () => this.setState({isCaptureModalOpen: true});
    closeCaptureModal = () => this.setState({isCaptureModalOpen: false});

    render() {
        return (
            <ThemeProvider theme={theme}>
                <React.Fragment>
                    <CaptureEmailModal
                        isOpen={this.state.isCaptureModalOpen}
                        closeEmailCaptureModal={this.closeCaptureModal}/>
                    <Layout header={<EmissionHeader openCaptureModal={this.openCaptureModal}/>}>
                        <SEO title="CO2 certificaten" keywords={[`poqqy`, `emissierechten`, `co2`, `certificaten`, `beleggen`, `duurzaam`]}/>
                        <ContentContainer>
                            <KeyPoints/>
                            <Divider/>
                            <Overview/>
                        </ContentContainer>
                    </Layout>
                    <Banner openEmailCaptureModal={this.openCaptureModal}/>
                    <Footer/>
                </React.Fragment>
            </ThemeProvider>
        )
    }
}

export default EmissionCertificatePage
