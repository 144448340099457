import React, { Component } from "react"
import styled from "styled-components"
import {
    ActionFooter,
    ButtonSpinnerContainer,
    CloseIcon,
    Content,
    Description,
    DescriptionInputGroup,
    ModalContentContainer,
    SmallDivider,
} from "./captureEmailModalStyles"
import PropTypes from "prop-types"
import ButtonSpinner from "../../../shared/components/buttonSpinner"
import { faExclamationCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GradientButton from "../../../shared/components/buttons/gradientButton"

const WarningIcon = styled(props => <FontAwesomeIcon {...props} />)`
    font-size: ${props => props.theme.fontSizes.size09};
    color: ${props => props.theme.colors.wildWaterLemon};
`

class CaptureEmailModalError extends Component {
    render() {
        return (
            <ModalContentContainer>
                <CloseIcon onClick={() => this.props.closeEmailCaptureModal()}
                           size={"lg"}
                           icon={faTimes}/>

                <Content>
                    <WarningIcon size={"2x"} icon={faExclamationCircle}/>
                    <SmallDivider/>

                    <DescriptionInputGroup>
                        <Description>
                            Er ging iets mis, we kunnen de server niet bereiken.
                        </Description>
                    </DescriptionInputGroup>
                </Content>

                <ActionFooter>
                    <GradientButton onClick={() => this.props.sendEmailSubscription()}>
                        {this.props.emailSubscriptionLoading && (
                            <ButtonSpinnerContainer>
                                <ButtonSpinner/>
                            </ButtonSpinnerContainer>
                        )}
                        Probeer opnieuw
                    </GradientButton>
                </ActionFooter>
            </ModalContentContainer>
        )
    }
}

CaptureEmailModalError.propTypes = {
    closeEmailCaptureModal: PropTypes.func,
    sendEmailSubscription: PropTypes.func,
    emailSubscriptionLoading: PropTypes.bool,
}

export default CaptureEmailModalError
