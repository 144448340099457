import React from "react"
import styled from "styled-components"
import plantsIconSrc from "../../images/plants-icon.svg";
import graphIconSrc from "../../images/graph-icon.svg";
import sustainableIconSrc from "../../images/sustainable-icon.svg";
import media from "../../shared/mediaQuery"

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const OverviewItem = styled.div`
    display: flex;
    flex-direction: row;
    
    ${media.phone`
        flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
    `};
`

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 5;
`

const SummaryTitle = styled.div`
    color: ${props => props.theme.colors.grey50};
    font-weight: 500;
    font-size: ${props => props.theme.fontSizes.size05};
    margin-bottom: ${props => props.theme.margins.size02};
`

const SummaryDescription = styled.div`
    font-size: ${props => props.theme.fontSizes.size03};
    color: ${props => props.theme.colors.grey65};
`

const TextDivider = styled.div`
    margin-bottom: ${props => props.theme.margins.size01};
`

const ImageContainer = styled.div`
    display: flex;
    flex: 1.5;
    align-items: center;
    justify-content: center;
    
    ${media.phone`
        align-items: center;
        margin-bottom: ${props => props.theme.margins.size06};
    `};
`

const Image = styled.img`
    z-index: 2;
    
    ${media.phone`
        height: 70px;
    `};
`

const ColorfulCircle = styled.div`
    position: absolute;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.sulu95};
    z-index: 1;
`

const Divider = styled.div`
    margin-bottom: ${props => props.theme.margins.size11};
    
    ${media.phone`
        margin-bottom: ${props => props.theme.margins.size08};
    `};
`

const Overview = () => {
    return (
        <Container>
            <OverviewItem reverse={true}>
                <SummaryContainer>
                    <SummaryTitle>
                        Mogelijkheid tot handel in certificaten
                    </SummaryTitle>
                    <SummaryDescription>
                        Bedrijven die minder emissierechten beschikbaar hebben dan zij hebben uitgestoten,
                        moeten emissierechten bijkopen, terwijl bedrijven die minder hebben uitgestoten
                        dan zij aan emissierechten hebben,  hun overschot aan rechten mogen verkopen.
                        <TextDivider/>
                        Door de vraag naar en het aanbod van emissierechten krijgen emissierechten een prijs.
                    </SummaryDescription>
                </SummaryContainer>
                <ImageContainer>
                    <Image height={90} src={plantsIconSrc} alt='handel'/>
                    <ColorfulCircle/>
                </ImageContainer>
            </OverviewItem>

            <Divider/>

            <OverviewItem reverse={false}>
                <ImageContainer>
                    <Image height={90} src={graphIconSrc} alt='grafiek'/>
                    <ColorfulCircle/>
                </ImageContainer>

                <SummaryContainer>
                    <SummaryTitle>
                        Unieke kans voor de belegger
                    </SummaryTitle>
                    <SummaryDescription>
                        Emissierechten hebben een prijs als gevolg van vraag naar en het aanbod van
                        emissierechten.
                        <TextDivider/>
                        Indien bijvoorbeeld de vraag stijgt, dan stijgt de prijs als gevolg ook mee.
                        Ook is het mogeliijk de cap van het maximale hoeveelheid emissierechten wordt verlaagd,
                        waardoor het aanbod daalt en de prijs als gevolg stijgt.
                        <TextDivider/>
                        Het is dus als investeerder mogelijk om een voordeel te behalen
                        door stijgingen in de prijs van emissierechten.
                    </SummaryDescription>
                </SummaryContainer>
            </OverviewItem>

            <Divider/>

            <OverviewItem reverse={true}>
                <SummaryContainer>
                    <SummaryTitle>
                        Duurzame wereld
                    </SummaryTitle>
                    <SummaryDescription>
                        Het is mogelijk om emissierechten te vernietigen, nadat deze zijn aangeschaft.
                        Het resultaat is dat de CO2 die gekoppeld is aan jouw emissierecht niet meer door
                        de industrie kan worden uitgestoten.
                        <TextDivider/>
                        Hoe meer mensen emissierechten aanschaffen en vervolgens vernietigen,
                        hoe minder vervuiling er mogelijk is.
                        <TextDivider/>
                        Een aanvullend gevolg is dat er minder rechten in de handel zijn.
                        Dit leidt ertoe dat de overgebleven emissierechten duurder worden.
                    </SummaryDescription>
                </SummaryContainer>
                <ImageContainer>
                    <Image height={90} src={sustainableIconSrc} alt='handel'/>
                    <ColorfulCircle/>
                </ImageContainer>
            </OverviewItem>
        </Container>
    )
}

export default Overview
