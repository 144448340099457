import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    
    ${props => props.isOpen && css`
        transform: scale(1.0);
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    `}
`;

const ModalBox = styled.div`
    box-sizing: border-box;
    position: relative;
    background-color: white;
    border-radius: 6px;
    width: ${props => props.width || '350px'};
`;

const Modal = (props) => {
    return (
        <ModalContainer isOpen={props.isOpen}>
            <ModalBox width={props.width}>
                {props.children}
            </ModalBox>
        </ModalContainer>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired
};

export default Modal;
