import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {
    ActionFooter,
    ButtonSpinnerContainer,
    CloseIcon,
    Content,
    Description,
    DescriptionInputGroup,
    ModalContentContainer,
    SmallDivider,
} from "./captureEmailModalStyles"
import Checkbox from "../../../shared/components/checkbox"
import ButtonSpinner from "../../../shared/components/buttonSpinner"
import emailIconSrc from "../../../images/email-icon.svg"
import { faTimes } from "@fortawesome/free-solid-svg-icons/index"
import GradientButton from "../../../shared/components/buttons/gradientButton"
import Input from "../../../shared/components/inputs/input"


const EmailImg = styled.img`
    height: 70px;
`

const Permissions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PermissionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.margins.size02};
    width: 85%;
    cursor: pointer;
`

const PermissionText = styled.span`
    line-height: ${props => props.theme.fontSizes.size05};
    font-size: ${props => props.theme.fontSizes.size01};
    color: ${props => props.theme.colors.grey50};
    text-align: left;
    margin-left: ${props => props.theme.margins.size02};
`

class CaptureEmailModalContent extends Component {
    render() {
        return (
            <ModalContentContainer>
                <CloseIcon onClick={() => this.props.closeEmailCaptureModal()}
                           size={"lg"}
                           icon={faTimes}/>

                <Content>
                    <EmailImg src={emailIconSrc} alt='email icon'/>
                    <SmallDivider/>

                    <DescriptionInputGroup>
                        <Description>
                            Hey! Ons platform is momenteel nog niet beschikbaar, laat hieronder je email achter.
                            We houden je op de hoogte!
                        </Description>

                        <SmallDivider/>

                        <Input width='70%'
                                       placeholder='Je email'
                                       value={this.props.email}
                                       onChange={(event) => this.props.changeEmail(event.target.value)}
                        />

                        <SmallDivider/>

                        <Permissions>
                            <PermissionContainer
                                onClick={(value) => this.props.changeEmailPermission(value)}>
                                <Checkbox checked={this.props.emailPermission}
                                          readOnly={true}
                                          id='permission'/>
                                <PermissionText>
                                    Ja, mijn email mag gebruikt worden om mij op de hoogte te houden van ontwikkelingen
                                    van het platform
                                </PermissionText>
                            </PermissionContainer>
                        </Permissions>

                    </DescriptionInputGroup>
                </Content>

                <ActionFooter>
                    <GradientButton disabled={!this.props.emailPermission}
                                    onClick={this.props.sendEmailSubscription}>
                        {this.props.emailSubscriptionLoading && (
                            <ButtonSpinnerContainer>
                                <ButtonSpinner/>
                            </ButtonSpinnerContainer>
                        )}
                        Inschrijven
                    </GradientButton>
                </ActionFooter>
            </ModalContentContainer>
        )
    }
}

CaptureEmailModalContent.propTypes = {
    emailPermission: PropTypes.bool,
    emailSubscriptionLoading: PropTypes.bool,
    sendEmailSubscription: PropTypes.func,
    changeEmailPermission: PropTypes.func,
    changeEmail: PropTypes.func,
    closeEmailCaptureModal: PropTypes.func,
    email: PropTypes.string,
}

export default CaptureEmailModalContent
