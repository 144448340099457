import React from "react"
import styled from "styled-components"
import walletIconSrc from "../../images/wallet-icon.svg";
import emissionIconSrc from "../../images/emission-icon.svg";
import sectorIconSrc from "../../images/sector-icon.svg";
import media, { Mobile, TabletAndDesktop } from "../../shared/mediaQuery"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    
    ${media.phone`
        flex-direction: column;
    `}
`

const KeyPointItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    ${media.phone`
        margin-bottom: ${props => props.theme.margins.size06};
    `}
`

const Description = styled.div`
    margin-top: ${props => props.theme.margins.size03};
    text-align: center;
    color: ${props => props.theme.colors.grey65};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: 500;
`

const Illustration = styled.img`
    margin-top: ${props => props.marginTop};
`;

const KeyPoints = () => {
    return (
        <Container>
            <KeyPointItem>
                <Mobile>
                    <Illustration height={60} src={walletIconSrc} alt={'co2 certificaat'} />
                </Mobile>
                <TabletAndDesktop>
                    <Illustration marginTop='12px' height={80} src={walletIconSrc} alt={'co2 certificaat'} />
                </TabletAndDesktop>
                <Description>Eén certificaat is gelijk <br/>aan één ton CO2 uitstoot</Description>
            </KeyPointItem>

            <KeyPointItem>
                <Mobile>
                    <Illustration height={70} src={emissionIconSrc} alt={'co2 uitstoot'} />
                </Mobile>
                <TabletAndDesktop>
                    <Illustration height={90} src={emissionIconSrc} alt={'co2 uitstoot'} />
                </TabletAndDesktop>
                <Description>Er is een Europese limiet <br/> opgesteld</Description>
            </KeyPointItem>

            <KeyPointItem>
                <Mobile>
                    <Illustration height={60} src={sectorIconSrc} alt={'bedrijven'} />
                </Mobile>
                <TabletAndDesktop>
                    <Illustration marginTop='18px' height={70} src={sectorIconSrc} alt={'bedrijven'} />
                </TabletAndDesktop>
                <Description>Voornamelijk bedrijven handelen <br/> in deze emissie-rechten</Description>
            </KeyPointItem>
        </Container>
    )
}

export default KeyPoints
