import React from 'react';
import styled from 'styled-components';

const LoadingSpinner = styled.div`
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 0.8s linear infinite;
    border: 2px solid white;border-bottom-color: transparent;border-left-color: transparent;
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const ButtonSpinner = () => {
    return (
        <LoadingSpinner/>
    );
};

export default ButtonSpinner;
