import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

export const CloseIcon = styled(props => <FontAwesomeIcon {...props} />)`
    position: absolute;
    cursor: pointer;
    top: ${props => props.theme.margins.size04};
    right: ${props => props.theme.margins.size04};
    color: ${props => props.theme.colors.grey80};
    font-size: ${props => props.theme.fontSizes.size05};
    
    &:hover {
      opacity: 0.8;
    }
`

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: ${props => props.theme.margins.size05} ${props => props.theme.margins.size05};
`

export const Description = styled.span`
    text-align: center;
    line-height: ${props => props.theme.fontSizes.size05};
    font-size: ${props => props.theme.fontSizes.size02};
    color: ${props => props.theme.colors.grey40};
`

export const DescriptionInputGroup = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ActionFooter = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.colors.grey98};
    width: 100%;
    box-sizing: border-box;
    padding: ${props => props.theme.margins.size05} ${props => props.theme.margins.size04};
    justify-self: flex-end;
    border-radius: 0 0 6px 6px;
`

export const ButtonSpinnerContainer = styled.div`
    margin-right: ${props => props.theme.margins.size02};
`

export const SmallDivider = styled.div`
    margin-bottom: ${props => props.theme.margins.size05};
`
